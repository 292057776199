<template>
  <div class="camera-capture">
    <video
      v-show="!value"
      style="border-radius: 6px;"
      ref="video"
      width="320"
      height="240"
      autoplay
      :playsInline="true"
    ></video>
    <canvas
      v-show="value"
      style="border-radius: 6px;"
      ref="canvas"
      width="320"
      height="240"
    ></canvas>
    <v-btn x-large color="green mt-3" dark @click="capturePhoto" v-if="!value" :loading="loading"> <span class="fonte-bold white--text">Capturar</span> </v-btn>
    <v-btn x-large color="green mt-3" dark @click="resetPhoto" v-else :loading="loading"><span class="fonte-bold white--text">Tentar Novamente </span> </v-btn>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
export default {
  props: ["value", "path"],
  data() {
    return {
      loading: false,
      show_canvas: false,
      show_video: true,
    }
  },
  name: "CameraCapture",
  methods: {
    resetPhoto() {
      this.$emit("input", null);
      setTimeout(() => {
        this.startCamera();
      }, 300);
    },
    // verificarSeCameraTemAcesso() {
    //   if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    //     alert(
    //       "A API do dispositivo não suporta a caixa de captura de imagem."
    //     )
    //   }

    // },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error("Erro ao acessar a câmera do dispositivo:", error);
      }
    },
    stopCamera() {
      const stream = this.$refs.video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      this.$refs.video.srcObject = null;
    },
    capturePhoto() {
      const self = this;
      self.loading = true;
      const context = this.$refs.canvas.getContext("2d");
      const canvas = this.$refs.canvas;
      context.drawImage(
        this.$refs.video,
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      canvas.toBlob(async blob => {
        var metadata = {
          contentType: "image/png"
        };

        const storage = getStorage();
        const storageRef = ref(storage);
        const imagesRef = ref(
          storageRef,
          `${self.path}/selfie.png`
        );
        const taskLoad = uploadBytesResumable(imagesRef, blob, metadata);
        taskLoad.on(
          "state_changed",
          //get progress
          snapshot => {
            // self.loading =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            alert('Erro ao fazer upload, tente novamente.');
            self.resetPhoto();
          },
          //success
          () => {
            getDownloadURL(taskLoad.snapshot.ref).then(link => {
              self.$emit("input", link);
              self.loading = false;
              self.stopCamera();
              // setTimeout(() => {
              //   // reiniciar camera
              // }, 1000);
            });
          }
        );
        
      });
    }
  }
};
</script>

<style>
.camera-capture {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
</style>
